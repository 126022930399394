import React from "react";

import styles from "./A.module.css";

const A = ({ href, children }) => (
  <a className={styles.A} href={href}>
    {children}
  </a>
);

export default A;
