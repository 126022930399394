import Typography from "typography";
import Wordpress2016 from "typography-theme-wordpress-2016";

// Import typefaces
import "typeface-heebo";

Wordpress2016.overrideThemeStyles = () => ({
  h1: {
    fontFamily: ["Heebo", "sans-serif"].join(",")
  },
  "a.gatsby-resp-image-link": {
    boxShadow: "none"
  }
});

Wordpress2016.bodyFontFamily = ["Heebo", "sans-serif"];
Wordpress2016.headerFontFamily = ["Heebo", "sans-serif"];

delete Wordpress2016.googleFonts;

const typography = new Typography(Wordpress2016);

// Hot reload typography in development.
if (process.env.NODE_ENV !== "production") {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
