import React from "react";

import profilePic from "../../assets/profile-pic.jpg";

import A from "../A";

import styles from "./ExtendedBio.module.css";

class ExtendedBio extends React.Component {
  render() {
    return (
      <>
        <h1 className={styles.ExtendedBioHeading}>Hi, I'm Ardy.</h1>
        <div className={styles.ExtendedBioContent}>
          <p>
            I'm an indie maker based in Edmonton, Canada and the founder of{" "}
            <a href="https://boundlessweb.ca">Boundless Web Solutions</a>.
            <br />
            <br />I make products solving problems that keep me up at night.
          </p>
          <div className={styles.ExtendedBioPicContainer}>
            <div className={styles.ExtendedBioPicWrapper}>
              <img
                src={profilePic}
                alt={`Ardalan Naghshineh`}
                className={styles.ExtendedBioPic}
              />
            </div>
          </div>
        </div>
        <ul className={styles.ExtendedBioLinksList}>
          <li>
            <A href="https://www.linkedin.com/in/ardalann/">LinkedIn</A>
          </li>
          <li>
            <A href="https://twitter.com/ardalanme">Twitter</A>
          </li>
          <li>
            <A href="https://www.indiehackers.com/ardalan">Indie Hackers</A>
          </li>
          <li>
            <A href="/fa/">فارسی</A>
          </li>
        </ul>
      </>
    );
  }
}

export default ExtendedBio;
