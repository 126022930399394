import React from "react";
import { Link, graphql } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";

import ExtendedBio from "../components/ExtendedBio";
import Layout from "../components/Layout";
import { rhythm } from "../utils/typography";

import profilePic from "../assets/profile-pic.jpg";

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const siteDescription = get(
      this,
      "props.data.site.siteMetadata.description"
    );
    const posts = get(this, "props.data.allMarkdownRemark.edges");
    const twitterHandle = get(
      this,
      "props.data.site.siteMetadata.social.twitter"
    );
    const siteUrl = get(this, "props.data.site.siteMetadata.siteUrl");

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          meta={[{ name: "description", content: siteDescription }]}
          title={siteTitle}
        >
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={twitterHandle} />
          <meta name="twitter:creator" content={twitterHandle} />
          <meta name="twitter:image" content={`${siteUrl}${profilePic}`} />
          <meta property="og:title" content={siteTitle} />
          <meta property="og:image" content={`${siteUrl}${profilePic}`} />
          <meta property="og:description" content={siteDescription} />
        </Helmet>
        <ExtendedBio />
        {posts &&
          posts.map(({ node }) => {
            const title = get(node, "frontmatter.title") || node.fields.slug;
            return (
              <div key={node.fields.slug}>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4)
                  }}
                >
                  <Link style={{ boxShadow: "none" }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
                <small>{node.frontmatter.date}</small>
                <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
              </div>
            );
          })}
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
        social {
          twitter
        }
      }
    }
    # allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    #   edges {
    #     node {
    #       excerpt
    #       fields {
    #         slug
    #       }
    #       frontmatter {
    #         date(formatString: "DD MMMM, YYYY")
    #         title
    #       }
    #     }
    #   }
    # }
  }
`;
