import React from "react";
import { Link } from "gatsby";

import { rhythm } from "../../utils/typography";

import styles from "./Layout.module.css";

class Template extends React.Component {
  render() {
    const { location, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    let header;
    const headerContent = (
      <Link
        style={{
          boxShadow: "none",
          textDecoration: "none",
          color: "inherit"
        }}
        to={"/"}
      >
        Ardalan Naghshineh
      </Link>
    );

    if (location.pathname === rootPath) {
      // header = (
      //   <h1
      //     style={{
      //       ...scale(0.5),
      //       marginBottom: rhythm(1.5),
      //       marginTop: rhythm(2.5)
      //     }}
      //   >
      //     {headerContent}
      //   </h1>
      // );
    } else {
      header = (
        <h3
          style={{
            marginTop: 0,
            marginBottom: rhythm(-1)
          }}
        >
          {headerContent}
        </h3>
      );
    }
    return (
      <div className={styles.Layout}>
        {header}
        {children}
      </div>
    );
  }
}

export default Template;
